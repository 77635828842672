





















































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { DocumentsActionTypes } from '@store/documents/Types'
import { documentsNamespace } from '@store/documents'
import { IDocumentTypes } from '@store/documents/Interface'
import { userNamespace } from '@store/user'
import PerfectScrollbar from 'perfect-scrollbar'
import { IPagination } from '@/interfaces/Interface'
import { IProject } from '@store/user/Interface'

@Component({
  components: {
    'v-document': () => import('@/components/Document.vue'),
    'v-form-upload': () => import('@/components/forms/FormUploadDoc.vue'),
  },
})
export default class CardDocuments extends Vue {
  @Prop({ default: () => null })
  private type!: IDocumentTypes

  @documentsNamespace.State('documentTypes')
  private documentTypes!: IDocumentTypes[]

  @documentsNamespace.State('documentByTypes')
  private documentByTypes!: IDocumentTypes[]

  @userNamespace.Getter('project')
  private selectedProject!: IProject

  @userNamespace.State('selectedProject')
  private selectedProjectId!: number

  @documentsNamespace.Action(DocumentsActionTypes.A_DOCUMENTS_BY_TYPES)
  private fetchDocumentsByType!: (params) => Promise<any>

  @documentsNamespace.Action(DocumentsActionTypes.CLEAR_DOCUMENTS_BY_TYPES)
  private clearDocumentsByType!: (type) => Promise<any>

  private isLoadDocuments: boolean = false
  private showFormUpload: boolean = false
  private homeBody!: any
  private ps: any

  private pagination: IPagination = {
    lastPage: 1,
    currentPage: 1,
  }

  private mounted() {
    this.homeBody = this.$refs.cardBody

    this.ps = new PerfectScrollbar(this.homeBody)
    this.homeBody.addEventListener('ps-y-reach-end', () => this.loadMore())

    this.fetchDocumentsByType({ projectId: this.selectedProjectId, category: this.type }).then(() => {
      this.isLoadDocuments = true
    })
  }

  get disabledCategory() {
    if (this.selectedProject.work_list_requested) {
      const arrId = [1, 2, 3]
      if (arrId.findIndex(item => item === this.type.id) !== -1) {
        return true
      }
    }
    return false
  }

  get categoryName(): string {
    return this.type.bpm_entity_name || ''
  }

  private updateDocument() {
    this.isLoadDocuments = false
    this.clearDocumentsByType(this.type).then(() => {
      this.fetchDocumentsByType({ projectId: this.selectedProjectId, category: this.type }).then(() => {
        this.isLoadDocuments = true
      })
    })
  }
  private loadMore() {
    if (this.isLoadDocuments && this.documentByTypes[this.categoryName].lastPage > this.pagination.currentPage) {
      this.pagination.currentPage += 1
      this.fetchDocumentsByType({
        projectId: this.selectedProjectId,
        category: this.type,
        currentPage: this.pagination.currentPage,
      })
    }
  }

  private destroyed() {
    this.clearDocumentsByType(null)
  }

  private typeDocuments(id) {
    return this.documentTypes.find(type => Number(type.id) === Number(id))
  }
}
